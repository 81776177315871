// Used for a neutral button
@import '../_colors';

.btn-neutral {
  @include button-variant($color-disabled-two, $color-disabled-two, $color-menu-default, $color-menu-default, darken($color-menu-default, 10%), darken($color-menu-default,10%));
  color: $color-menu-default;
}

.btn-light-neutral {
  @include button-custom-variant(
		$color-disabled-two, $color-disabled-two, $color-menu-default, null, null,
    $color-menu-default, $color-menu-default, $color-white, null, null,
    $color-menu-default, $color-menu-default, $color-white, null, null,
	);
}

.btn-empty {
  @include button-custom-variant(
		$color-white, $color-white, $color-menu-default, null, null,
    $color-menu-default, $color-menu-default, $color-white, null, null,
    $color-menu-default, $color-menu-default, $color-white, null, null,
	);

  //$background, $border, $color, $icon, $box-shadow,
    // $hover-background, $hover-border, $hover-color, $hover-icon, $hover-box-shadow,
    // $active-background, $active-border, $active-color, $active-icon, $active-box-shadow) {
}

.form-control:disabled, .form-control[readonly] {
  color: $color-gs-three;
}