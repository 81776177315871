//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

@import '_colors';

// Theme colors
// Override primary color variants
$primary: $color-pri-blue-one;
$primary-hover: $color-pri-blue-three; // Custom variable
$primary-light: $color-pri-blue-two; // Custom variable
$primary-inverse: $color-white; // Custom variable

// Secondary colors
$secondary: $color-disabled-one;
$secondary-light: $color-disabled-two;

// Success colors
$success: $color-ome-green;
$success-hover: $color-ome-green-three;
$success-light: $color-ome-green-two; // Custom variable

// Warning colors
$warning: $color-amber-one;
$warning-light: $color-amber-four; // Custom variable

// Danger colors
$danger: $color-pri-red-one;
$danger-light: $color-pri-red-two; // Custom variable

$gray-600: $color-disabled-one;

// Text colors
$theme-text-colors: (
        'priOne': $color-pri-one,
        'disabledOne': $color-disabled-one,
        'gsThree': $color-gs-three,
        'dark': $color-pri-one
);


// Root font size
$root-font-size: 12px; // Root font size for desktop mode

// Inputs
$input-border-color: $color-disabled-one;
$input-focus-color: $color-pri-one;
$input-focus-border-color: $color-pri-blue-one;
$input-readonly-bg: $color-gs-one;
$input-disabled-bg: $color-gs-one;
$input-disabled-color: $color-gs-one;

$card-border-radius: 1rem;
$card-spacer-y: 30px;
$card-spacer-x: 30px;

$border-radius: 6px;
$btn-border-radius: 6px;
$btn-border-radius-lg: 6px;
$btn-border-radius-sm: 6px;
