// 3rd-party Plugins
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import '~react-toastify/dist/ReactToastify.css';

// Theme Styles
@import './vendor/_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './vendor/_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './vendor/_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './vendor/_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './vendor/_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Brand themes
.brand-dark {
  @import './vendor/_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
  @import './vendor/_metronic/_assets/sass/themes/layout/brand/light.scss';
}
